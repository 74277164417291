import styled from "@emotion/styled";

export const Container = styled.div``;

export const StyledButton = styled.button`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: stretch;

  inline-size: 100%;
  padding: 20px;

  color: #000d37;
  font-size: 16px;
  font-family: Roboto;
  line-height: 19px;
  text-transform: uppercase;

  border: none;
  border-radius: 5px;

  background-color: #ffca05;

  cursor: pointer;
`;
