/**
 * @generated SignedSource<<48a071d91a979a40aede9f251e41412d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleCommentsTriggerArticle$data = {
  readonly commentCount: number | null | undefined;
  readonly entityId: string;
  readonly " $fragmentType": "articleCommentsTriggerArticle";
};
export type articleCommentsTriggerArticle$key = {
  readonly " $data"?: articleCommentsTriggerArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleCommentsTriggerArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleCommentsTriggerArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commentCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "0d2c35259bd15a0a2ef392f3d1edb80f";

export default node;
