import type { SharePositionType } from "@product/scmp-sdk";
import { atom } from "jotai";

export type ContentShareWidgetDrawerState = {
  isOpen: boolean;
  position: SharePositionType;
};

export const contentShareWidgetDrawerAtom = atom<ContentShareWidgetDrawerState>({
  isOpen: false,
  position: "article_adhesive",
});
