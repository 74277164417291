import styled from "@emotion/styled";

import CommentIcon from "./comment-button-icon.svg";

export const Container = styled.div``;

export const StyledButton = styled.button`
  display: flex;
  flex-flow: row;
  gap: 4px;
  justify-content: center;
  align-items: center;

  inline-size: 100%;
  min-inline-size: max-content;
  padding: 12px;

  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  font-family: Roboto;
  line-height: 18.75px;
  text-transform: uppercase;

  border: none;
  border-radius: 2px;

  background-color: #4585ff;

  cursor: pointer;

  fill: #ffffff;
`;

export const CommentText = styled.span`
  display: flex;
  flex-flow: row;
  gap: 4px;
  justify-content: center;
  align-items: center;

  :hover span {
    text-decoration: underline;
  }
`;

export const StyledCommentIcon = styled(CommentIcon)`
  gap: 0;

  inline-size: 15px;
  block-size: 15.83px;
  margin-block: 1.67px 2.5px;
  margin-inline: 2.5px 2.5px;
`;

export const StyledCommentWrapper = styled.div`
  inset-block-start: 1.67px;
  inset-inline-start: 2.5px;

  inline-size: 20px;
  block-size: 20px;
`;
