import pluralize from "pluralize";
import { forwardRef } from "react";

import {
  CommentText,
  Container,
  StyledButton,
  StyledCommentIcon,
  StyledCommentWrapper,
} from "./styles";

type Props = {
  className?: string;
  commentCount: number;
  onClick?: () => void;
};

export const Article20CommentsBanner = forwardRef<HTMLDivElement, Props>(
  ({ className, commentCount, onClick }, reference) => (
    <Container className={className} ref={reference}>
      <StyledButton onClick={onClick}>
        <StyledCommentWrapper>
          <StyledCommentIcon />
        </StyledCommentWrapper>
        <CommentText>
          {(commentCount ?? 0) > 0 ? (
            <>
              <strong>{commentCount}</strong> <span>{pluralize("Conversation", commentCount)}</span>
            </>
          ) : (
            <span>Start Conversation</span>
          )}
        </CommentText>
      </StyledButton>
    </Container>
  ),
);
Article20CommentsBanner.displayName = "Article20CommentsBanner";
