import { forwardRef } from "react";

import CommentIcon from "./comment-button-icon.svg";
import { Container, StyledButton } from "./styles";

type Props = {
  className?: string;
  commentCount: number;
  onClick?: () => void;
};

export const ArticleCommentsBanner = forwardRef<HTMLDivElement, Props>(
  ({ className, commentCount, onClick }, reference) => (
    <Container className={className} ref={reference}>
      <StyledButton onClick={onClick}>
        <span>Conversations {(commentCount ?? 0) > 0 && <strong>({commentCount})</strong>}</span>
        <CommentIcon />
      </StyledButton>
    </Container>
  ),
);
ArticleCommentsBanner.displayName = "ArticleCommentsBanner";
