import { forwardRef, type ReactNode } from "react";

import { CountMessage, Icon, StyledButton } from "./styles";

type Props = {
  className?: string;
  commentCount: number;
  icon?: ReactNode;
  onClick?: () => void;
};

export const ArticleCommentsButton = forwardRef<HTMLButtonElement, Props>(
  ({ className, commentCount, icon, onClick }, reference) => {
    const hasComment = commentCount > 0;
    const commentCountDisplay = commentCount > 99 ? "99+" : commentCount;
    return (
      <StyledButton
        $hasComment={hasComment}
        className={className}
        onClick={onClick}
        ref={reference}
      >
        {hasComment && <CountMessage>{commentCountDisplay}</CountMessage>}
        {icon ?? <Icon />}
      </StyledButton>
    );
  },
);

ArticleCommentsButton.displayName = "ArticleCommentsButton";
