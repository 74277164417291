import styled from "@emotion/styled";
import Badge from "@mui/material/Badge";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import IconComment from "./icon-comment.svg";

export const StyledIconComment = styled(IconComment)``;

export const StyledBadge = styled(Badge)`
  ${".MuiBadge-standard"} {
    inset-block-start: 6px;
    inset-inline-end: -1px;

    display: flex;
    justify-content: center;
    align-items: center;

    inline-size: 17px;
    block-size: 17px;
    min-inline-size: 17px;

    color: #ffffff;
    font-weight: 400;
    font-size: 12px;
    font-family: ${fontRobotoCondensed};
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.48px;

    background-color: #eb4254;
    font-variant-numeric: lining-nums proportional-nums;
  }
`;

export const Container = styled.div`
  ${StyledIconComment} {
    border-radius: 50%;

    cursor: pointer;

    :hover {
      background-color: #dddddd;
    }
  }
`;
