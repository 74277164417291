import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import CommentIcon from "./comment-widget-icon.svg";

type Props = {
  $hasComment: boolean;
};
export const StyledButton = styled.button<Props>`
  position: relative;

  inline-size: 20px;
  block-size: 20px;
  margin-inline-end: ${props => (props.$hasComment ? "10px" : "0")};
  padding: 0;

  border: none;

  background-color: transparent;

  cursor: pointer;
`;

export const Icon = styled(CommentIcon)`
  inline-size: 100%;
  block-size: 100%;
`;

export const CountMessage = styled.span`
  position: absolute;
  inset-block-start: -2px;
  inset-inline-start: 12px;

  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  inline-size: 17px;
  block-size: 17px;
  margin-inline-end: 10px;

  color: #ffffff;
  font-size: 12px;
  font-family: ${fontRobotoCondensed};
  line-height: 14px;
  letter-spacing: -0.48px;

  border-radius: 50%;

  background-color: #eb4254;
`;
