import styled from "@emotion/styled";

import CommentIcon from "./comment-widget-icon.svg";

export const StyledButton = styled.button`
  position: relative;

  inline-size: 18px;
  block-size: 19px;
  padding: 0;

  border: none;

  background-color: transparent;

  cursor: pointer;
`;

export const Icon = styled(CommentIcon)`
  inline-size: 100%;
  block-size: 100%;
`;
