import { forwardRef } from "react";

import type { ContainerProps } from "./styles";
import { Container, CountMessage, NoPostMessage, StyledButton, StyledCommentIcon } from "./styles";

export type Props = {
  className?: string;
  commentCount: number;
  onClick?: () => void;
  variant?: ContainerProps["$variant"];
} & React.DetailsHTMLAttributes<HTMLDivElement>;

export const ArticleCommentsWidget = forwardRef<HTMLDivElement, Props>(
  ({ className, commentCount, onClick, variant }, reference) => (
    <Container $variant={variant} className={className} ref={reference}>
      {commentCount > 0 ? (
        <CountMessage>{commentCount}</CountMessage>
      ) : (
        <NoPostMessage>Post</NoPostMessage>
      )}
      <StyledButton onClick={onClick}>
        <StyledCommentIcon />
      </StyledButton>
    </Container>
  ),
);

ArticleCommentsWidget.displayName = "ArticleCommentsWidget";
