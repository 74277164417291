import { forwardRef } from "react";

import { Container, StyledBadge, StyledIconComment } from "./styles";

type Props = {
  className?: string;
  commentCount?: number;
  onClick?: () => void;
};

export const Article20Comment = forwardRef<HTMLDivElement, Props>(
  ({ className, commentCount, onClick }, reference) => (
    <Container className={className} onClick={onClick} ref={reference}>
      <StyledBadge badgeContent={commentCount}>
        <StyledIconComment />
      </StyledBadge>
    </Container>
  ),
);

Article20Comment.displayName = "Article20Comment";
